import { useQuery } from 'react-query';

import { realEstateApi } from '@/services';
import getHostname from '@/utils/getHostname';
import parseRealEstateItems from '@/utils/parseRealEstateItems';

const fetchTopResults = async (portal) => {
  const response = await realEstateApi('/search/top/5', { headers: { domain: portal } });
  return { result: response.data.data.map((item) => parseRealEstateItems(item)) };
};

const useQueryTopEstates = () => {
  const portal = getHostname();
  return useQuery('topEstates', () => fetchTopResults(portal), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const prefetchTopEstates = (portal) => async (queryClient) => {
  return queryClient.prefetchQuery('topEstates', () => fetchTopResults(portal));
};

export default useQueryTopEstates;

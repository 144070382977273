import { motion, useTransform } from 'framer-motion';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Fade = styled(motion.div)`
  position: absolute;

  background: linear-gradient(
    ${({ side }) => (side === 'left' ? '270deg' : '90deg')},
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.99) 99%
  );

  ${({ side }) => (side === 'left' ? 'left: 0' : 'right: 0')};

  width: 10vw;
  top: 0;
  bottom: 0;
  z-index: 50;
  pointer-events: none;
`;

const GreyFade = styled(motion.div)`
  position: absolute;

  background: linear-gradient(
    ${({ side }) => (side === 'left' ? '270deg' : '90deg')},
    rgba(247, 247, 247, 0) 0%,
    rgba(247, 247, 247, 0.99) 99%
  );

  ${({ side }) => (side === 'left' ? 'left: 0' : 'right: 0')};

  width: 10vw;
  top: 0;
  bottom: 0;
  z-index: 50;
  pointer-events: none;
`;

const ScrollFade = ({ bgcolor, progress }) => {
  const rightProgress = useTransform(progress, (x) => 1 - x);
  return (
    <>
      {bgcolor ? (
        <>
          <GreyFade side="left" style={{ opacity: progress }} />
          <GreyFade style={{ opacity: rightProgress }} />
        </>
      ) : (
        <>
          <Fade side="left" style={{ opacity: progress }} />
          <Fade style={{ opacity: rightProgress }} />
        </>
      )}
    </>
  );
};

ScrollFade.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  progress: PropTypes.object.isRequired,
  bgcolor: PropTypes.bool.isRequired,
};

export default ScrollFade;

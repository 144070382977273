import { forwardRef, memo } from 'react';

import Section from '../Section';

const MeasureSection = memo(
  forwardRef((props, ref) => (
    <Section padded {...props}>
      <div style={{ width: '100%' }} ref={ref} />
    </Section>
  ))
);

export default MeasureSection;

/* eslint-disable react/forbid-prop-types */
import { motion } from 'framer-motion';
import { clamp } from 'popmotion';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

const SliderIndicator = ({ index, length, variants, delay, size, ...props }) => {
  if (length <= 1) return null;

  const containerWidth = clamp(0, 5 * (size * 2) - size, length * (size * 2) - size);
  const containerCenter = containerWidth / 2;
  const xOffset =
    length <= 3
      ? 0
      : clamp(0, length * (size * 2) - size - containerWidth, index * (size * 2) - containerCenter + size / 2);

  return (
    <Flex
      sx={{
        position: 'absolute',
        bottom: '15px',
        width: '100%',
        justifyContent: 'center',
        zIndex: 10,
        pointerEvents: 'none',
      }}
      {...props}
    >
      <Flex sx={{ width: `${containerWidth}px`, height: `${size}px`, overflowX: 'hidden' }}>
        <Flex
          as={motion.div}
          initial={false}
          animate={{ x: `-${xOffset}px` }}
          transition={{
            delay,
          }}
          sx={{ mx: `-${size / 2}px` }}
        >
          {[...Array(length)].map((_, lindex) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={lindex}
              as={motion.div}
              animate={index === lindex ? 'active' : 'inactive'}
              variants={variants}
              transition={{
                delay,
              }}
              initial={false}
              sx={{
                mx: `${size / 2}px`,
                flexShrink: 0,
                height: `${size}px`,
                width: `${size}px`,
                bg: 'white',
                borderRadius: 100,
              }}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

SliderIndicator.propTypes = {
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  variants: PropTypes.shape({
    active: PropTypes.object.isRequired,
    inactive: PropTypes.object.isRequired,
  }),
  delay: PropTypes.number,
  size: PropTypes.number,
};

SliderIndicator.defaultProps = {
  variants: {
    active: {
      opacity: 1,
      scale: 1,
    },
    inactive: {
      opacity: 0.8,
      scale: 0.8,
    },
  },
  delay: 0.3,
  size: 10,
};

export default SliderIndicator;

import { useWindowWidth } from '@react-hook/window-size';
import { useElementScroll } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Flex } from 'rebass';

import Button from '@/components/Button/Button';
import MeasureSection from '@/components/MeasureSection/MeasureSection';
import ScrollFade from '@/components/ScrollFade';
import useQueryTopEstates from '@/hooks/queries/useQueryTopEstates';
import useScrollBox from '@/hooks/useScrollBox';

import Card from '../EstateCard/EstateCard';
import CardRowIndicator from './CardRowIndicator';
import LikeButton from './LikeButton';

const RowCards = memo(({ isDragging }) => {
  const { data } = useQueryTopEstates();

  return (
    <Flex justifyContent={['space-evenly', 'flex-start', 'flex-start']} width={['100%']}>
      {data?.result?.map((item) => (
        <Card
          key={item.id}
          title={item.title}
          images={item.images}
          livingSpace={item.keyData.livingSpace}
          purchasePrice={item.purchasePrice}
          isRentalEstate={item.isRentalEstate}
          rentalEstate={item.rentalEstate}
          roomCount={item.keyData.roomCount}
          town={item.town}
          isDragging={isDragging}
          id={item.id}
          monthlyRate={item?.calcExampleRate?.monthlyRate}
          loanAmount={item?.calcExampleRate?.loanAmount}
          equityCapital={item?.calcExampleRate?.equityCapital}
          equityCapitalRatio={item?.calcExampleRate?.equityCapitalRatio}
          boundInterestRate={item?.calcExampleRate?.boundInterestRate}
          effectiveInterestRate={item?.calcExampleRate?.effectiveInterestRate}
          fixedInterestRate={item?.calcExampleRate?.fixedInterestRate}
          incidentalPurchaseCosts={item?.calcExampleRate?.incidentalPurchaseCosts}
          incidentalPurchaseCostsRatio={item?.calcExampleRate?.incidentalPurchaseCostsRatio}
          initialRepayment={item?.calcExampleRate?.initialRepayment}
          zinsBindungInJahren={item?.calcExampleRate?.konditionen?.zinsBindungInJahren}
          produktAnbieter={item?.calcExampleRate?.konditionen?.produktAnbieter}
          summeZahlungen={item?.calcExampleRate?.konditionen?.summeZahlungen}
          laufzeitInMonaten={item?.calcExampleRate?.konditionen?.laufzeitInMonaten}
          effektivZins={item?.calcExampleRate?.konditionen?.effektivZins}
          mx={['0px', '10px']}
        >
          <LikeButton id={item.id} isFavorite={item.isFavorite} queryKey="topEstates" />
        </Card>
      )) ?? null}
    </Flex>
  );
});

RowCards.propTypes = {
  isDragging: PropTypes.shape({}).isRequired,
};

const DragEventBlocker = ({ isDragging }) => {
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    isDragging.onChange((current) => {
      setDragging(current);
    });
  }, [isDragging]);

  return (
    <Box
      sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, pointerEvents: dragging ? 'auto' : 'none' }}
    />
  );
};

DragEventBlocker.propTypes = {
  isDragging: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

const CardRow = ({ bgcolor, transparent, startpage, ...props }) => {
  const { push } = useRouter();
  const { t } = useTranslation('content-page-immobiliensuche');

  const target = useRef(null);

  const scrollTarget = useRef(null);
  const { blockDragging, isDragging } = useScrollBox(scrollTarget);
  const { scrollXProgress } = useElementScroll(scrollTarget);

  const handleClick = () => {
    if (startpage) {
      push({
        pathname: '/immobilien/immobiliensuche/ergebnisse',
        query: { sorting: 3, roomsTo: -1, roomsFrom: -1, offerType: 2, address: '' },
      });
    } else {
      push({
        pathname: '/immobilien/immobiliensuche/ergebnisse',
        query: { sorting: 0, roomsTo: -1, roomsFrom: -1, offerType: 2, address: '' },
      });
    }
  };
  const [paddingLeft, setPaddingLeft] = useState(20);
  const [scrollWidth, setScrollWidth] = useState(1700);

  const setPadding = (width) => {
    // const contentWidth = document.getElementById('imageScrollContainer').offsetWidth;
    if (width > 1200) {
      const newPaddingLeft = (width - 1150) / 2;
      setPaddingLeft(newPaddingLeft);
      const newScrollWidth = 1700 + newPaddingLeft + newPaddingLeft;
      setScrollWidth(newScrollWidth);
    } else {
      setPaddingLeft(20);
      setScrollWidth(1740);
    }
  };
  const width = useWindowWidth();
  useLayoutEffect(() => {
    setPadding(width);
  }, [width]);

  return (
    <>
      <MeasureSection ref={target} />
      <Box sx={{ position: 'relative' }}>
        <Box
          ref={scrollTarget}
          bg={transparent ? 'transparent' : 'white'}
          sx={{
            py: 3,
            width: '100%',
            overflowX: 'scroll',
            transition: 'all 1s ease',
            scrollPadding: '15px',
            scrollMargin: '200px',
            flexWrap: 'nowrap',
            scrollbarWidth: 'none' /* Firefox */,
            '-webkit-overflow-scrolling': 'touch',
            '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
            '::-webkit-scrollbar': {
              display: 'none',
              height: 0,
              width: 0,
            },
          }}
          {...props}
        >
          <Flex
            role="list"
            width={[scrollWidth]}
            flex={1}
            px={['0', paddingLeft, paddingLeft]}
            sx={{ position: 'relative', '& > *': { flexShrink: 0 }, flexWrap: 'nowrap' }}
          >
            <RowCards isDragging={blockDragging} />
            <DragEventBlocker isDragging={isDragging} />
          </Flex>
        </Box>
        {bgcolor ? <ScrollFade progress={scrollXProgress} bgcolor /> : <ScrollFade progress={scrollXProgress} />}
      </Box>
      <Flex
        flexDirection="column"
        bg={transparent ? 'transparent' : 'white'}
        justifyContent="center"
        alignItems="center"
        pt="16px"
        sx={{ zIndex: 1 }}
      >
        <CardRowIndicator progress={scrollXProgress} />
        <Button mt="32px" mb="64px" onClick={handleClick}>
          {t('currentButtonLabel')}
        </Button>
      </Flex>
    </>
  );
};

CardRow.propTypes = {
  transparent: PropTypes.bool.isRequired,
  bgcolor: PropTypes.bool.isRequired,
  startpage: PropTypes.string.isRequired,
};

CardRow.defaultProps = {};

export default CardRow;

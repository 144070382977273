/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import { useMotionValue } from 'framer-motion';
import throttle from 'lodash.throttle';
import { useCallback, useEffect } from 'react';

const timing = (1 / 60) * 1000;
// eslint-disable-next-line no-bitwise
const decay = (v) => -0.1 * ((1 / timing) ^ 4) + v;

function useScrollBox(scrollRef) {
  const direction = useMotionValue(0);
  const momentum = useMotionValue(0);
  const lastScrollX = useMotionValue(0);
  const speed = useMotionValue(0);
  const isDragging = useMotionValue(0);
  const blockDragging = useMotionValue(0);
  const clickStartX = useMotionValue(-1);
  const scrollStartX = useMotionValue(-1);

  const scrollWrapperCurrent = scrollRef.current;

  const handleLastScrollX = useCallback(
    throttle((screenX) => {
      lastScrollX.set(screenX);
    }, timing),
    []
  );

  const handleMomentum = useCallback(
    throttle((nextMomentum) => {
      momentum.set(nextMomentum);
      scrollRef.current.scrollLeft += nextMomentum * timing * direction.get();
    }, timing),
    [scrollWrapperCurrent, direction]
  );

  useEffect(() => {
    const handleChange = () => {
      if (direction.get() !== 0) {
        if (momentum.get() > 0.01 && isDragging.get() === 0) {
          handleMomentum(decay(momentum.get()));
        } else if (isDragging.get() === 1) {
          momentum.set(speed.get());
        } else {
          direction.set(0);
        }
      }
    };

    momentum.onChange(handleChange);
    speed.onChange(handleChange);
    direction.onChange(handleChange);
    isDragging.onChange(handleChange);
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      const handleDragStart = (e) => {
        if (blockDragging.get() === 1) return;

        clickStartX.set(e.screenX);
        scrollStartX.set(scrollRef.current.scrollLeft);
        direction.set(0);
      };

      const handleDragMove = (e) => {
        if (blockDragging.get() === 1) return;

        e.preventDefault();
        e.stopPropagation();

        if (clickStartX.get() !== -1 && scrollStartX.get() !== -1) {
          const touchDelta = clickStartX.get() - e.screenX;
          scrollRef.current.scrollLeft = scrollStartX.get() + touchDelta;

          if (Math.abs(touchDelta) > 1) {
            isDragging.set(1);
            direction.set(touchDelta / Math.abs(touchDelta));
            speed.set(Math.abs((lastScrollX.get() - e.screenX) / timing));
            handleLastScrollX(e.screenX);
          }
        }
      };

      const handleDragEnd = () => {
        clickStartX.set(-1);
        scrollStartX.set(-1);
        isDragging.set(0);
      };

      if (scrollRef.current.ontouchstart === undefined) {
        scrollRef.current.onmousedown = handleDragStart;
        scrollRef.current.onmousemove = handleDragMove;
        scrollRef.current.onmouseup = handleDragEnd;
        scrollRef.current.onmouseleave = handleDragEnd;
      }
    }
  }, [scrollWrapperCurrent, clickStartX, isDragging, scrollStartX, handleLastScrollX, lastScrollX, scrollRef]);

  return { clickStartX, scrollStartX, isDragging, direction, momentum, lastScrollX, speed, blockDragging };
}

export default useScrollBox;

import { useTransform } from 'framer-motion';
import { snap } from 'popmotion';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import SliderIndicator from '@/components/ImageGallery/SliderIndicator';

const snapTo = snap(1);

const variants = {
  active: {
    backgroundColor: '#333333',
    border: 'solid 0px #d1d1d1',
    scale: 1,
  },
  inactive: {
    backgroundColor: '#ffffff',
    border: 'solid 1px #d1d1d1',
    scale: 0.8,
  },
};

const CardRowIndicator = ({ progress, length }) => {
  const snappedProgress = useTransform(progress, (x) => snapTo(x * (length - 1)));

  const [index, setIndex] = useState(0);

  useEffect(
    () =>
      snappedProgress.onChange((latest) => {
        setIndex(latest);
      }),
    [snappedProgress]
  );

  return <SliderIndicator index={index} length={length} variants={variants} delay={0} sx={{ position: 'relative' }} />;
};

CardRowIndicator.propTypes = {
  progress: PropTypes.shape({}).isRequired,
  length: PropTypes.number,
};

CardRowIndicator.defaultProps = {
  length: 5,
};

export default CardRowIndicator;
